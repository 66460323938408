import React from 'react';

const IconLoader = () => (
  <svg
    id="logo"
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M50 5L11 27V72L50 95L89 73V28L50 5Z"
      stroke="currentColor"
      strokeWidth={5}
    />
    <path
      id="B"
      d="M37.7622 68C36.5874 68 36 67.3724 36 66.1172V33.8828C36 32.6276 36.5874 32 37.7622 32H41.4878C42.9311 32 44.022 32.5858 44.7604 33.7573L57.599 54.318H57.7752C57.6913 53.3138 57.6493 52.3096 57.6493 51.3054V33.8828C57.6493 32.6276 58.2367 32 59.4115 32H63.2378C64.4126 32 65 32.6276 65 33.8828V66.1172C65 67.3724 64.4126 68 63.2378 68H59.7639C58.3206 68 57.2297 67.4142 56.4913 66.2427L43.401 45.3054H43.2248C43.3087 46.3096 43.3507 47.3138 43.3507 48.318V66.1172C43.3507 67.3724 42.7633 68 41.5885 68H37.7622Z"
      fill="currentColor"
    />
  </svg>
);

export default IconLoader;
