import React from 'react';

const IconLogo = () => (
  <svg width="80" height="92" viewBox="0 0 80 92" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M40 1L1 23V68L40 91L79 69V24L40 1Z"
      stroke="currentColor"
      strokeWidth={5}
    />
    <path
      d="M29.5191 61C28.5064 61 28 60.4596 28 59.3787V31.6213C28 30.5404 28.5064 30 29.5191 30H32.7309C33.9751 30 34.9155 30.5044 35.5521 31.5132L46.6198 49.2183H46.7717C46.6994 48.3536 46.6632 47.4888 46.6632 46.6241V31.6213C46.6632 30.5404 47.1696 30 48.1823 30H51.4809C52.4936 30 53 30.5404 53 31.6213V59.3787C53 60.4596 52.4936 61 51.4809 61H48.4861C47.2419 61 46.3015 60.4956 45.6649 59.4868L34.3802 41.4575H34.2283C34.3006 42.3222 34.3368 43.1869 34.3368 44.0516V59.3787C34.3368 60.4596 33.8304 61 32.8177 61H29.5191Z"
      fill="currentColor"
    />
  </svg>
);

export default IconLogo;
